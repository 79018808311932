<template>
      <v-container
        id="condor-fireplace"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section D <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >

                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section D: Fireplace
                    </div>
                    <div class="display-2 font-weight-light">
                    Condor Fireplace & Stone Company
                    </div>
                  <div class="display-2 font-weight-light">
                    (Time Frame- 6 Weeks)
                    </div>
                </template>
                <v-form>

                         <v-container class="py-0">

                            <v-row>


                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <v-text-field
                                        label="Date"
                                        color="black"
                                        type="date"
                                        v-model ="section.date_field"
                                    />
                                </v-col>
                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <v-text-field
                                        label="Closing Date"
                                        color="black"
                                        type="date"
                                        v-model="this.selected_user.BuildEnd"
                                    />
                                </v-col>
                                <v-col
                                cols="12"
                                md="4"
                                >
                                    <v-text-field
                                        label="Home#"
                                        color="black"
                                        v-model="this.selected_user.HomeNumber"
                                    />
                                </v-col>
                                <v-col
                                cols="12"
                                md="4"
                                >
                                    <v-text-field
                                        label="Cell#1"
                                        color="black"
                                        v-model="this.selected_user.CellNumber"
                                    />
                                </v-col>
                                <v-col
                                cols="12"
                                md="4"
                                >
                                    <v-text-field
                                        label="Cell#2"
                                        color="black"
                                        v-model="this.selected_user.CellNumber"
                                    />
                                </v-col>
                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <v-text-field
                                        label="Homeowner 1 First Name"
                                        color="black"
                                        v-model="this.selected_user.PrimaryFirstName"
                                    />
                                </v-col>
                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <v-text-field
                                        label="Homeowner 1 Last Name"
                                        color="black"
                                        v-model="this.selected_user.PrimaryLastName"
                                    />
                                </v-col>
                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <v-text-field
                                        label="Homeowner 2 First Name"
                                        color="black"
                                        v-model="this.selected_user.SecondaryFirstName"
                                    />
                                </v-col>
                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <v-text-field
                                        label="Homeowner 2 Last Name"
                                        color="black"
                                        v-model="this.selected_user.SecondaryLastName"
                                    />
                                </v-col>
                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <v-text-field
                                        label="Email #1"
                                        color="black"
                                        v-model="this.selected_user.PrimaryEmailAddress"
                                    />
                                </v-col>
                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <v-text-field
                                        label="Email #2"
                                        color="black"
                                        v-model="this.selected_user.SecondaryEmailAddress"
                                    />
                                </v-col>
                                <v-col
                                cols="12"
                                >
                                    <v-text-field
                                        label="Address & Legal"
                                        color="black"
                                        v-model="this.selected_user.Address"
                                    />
                                </v-col>

                            </v-row>

                            <v-divider></v-divider>

                            <v-row>

                                <v-col
                                cols="6"

                                >
                                    <v-text-field
                                        label="Based on Model At"
                                        color="black"
                                        v-model="section.based_on_model_at"
                                    />
                                </v-col>

                            </v-row>

                            <v-divider></v-divider>

                            <v-row>

                                <v-col
                                cols="12"
                                md="6"
                                >

                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                    <h3>INTERIOR STONE/BRICK FIREPLACE#1</h3>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                     <v-text-field
                                        label="Stone Selection"
                                        v-model="section.stone_selection_1"
                                    />
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                      <v-select
                                            :items="['STD. 2 1/2','Custom','Undecided']"
                                            item-value="item_value"
                                            label="Hearth Selection:"
                                            v-model="section.hearth_selection_1"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                      <v-select
                                            :items="['Yes','No','Undecided']"
                                            item-value="item_value"
                                            label="Raised Hearth"
                                            v-model="section.raised_hearth_1"
                                        ></v-select>
                                    </v-col>
                                     <v-col
                                    cols="12"
                                    md="6"
                                    >
                                      <v-select
                                            :items="['Yes','No','Undecided']"
                                            item-value="item_value"
                                            label="Stone Height to Mantel"
                                            v-model="section.stone_height_to_mantel_1"
                                        ></v-select>
                                    </v-col>
                                     <v-col
                                    cols="12"
                                    md="6"
                                    >
                                      <v-select
                                            :items="['Yes','No','Undecided']"
                                            item-value="item_value"
                                            label="Stone Height to Ceiling"
                                            v-model="section.stone_height_to_ceiling_1"
                                        ></v-select>
                                    </v-col>
                                     <v-col
                                    cols="12"
                                    md="6"
                                    >
                                      <v-select
                                            :items="['Yes','No','Undecided']"
                                            item-value="item_value"
                                            label="STD. 6in Corner Returns"
                                            v-model="section.std_six_corner_returns_1"
                                        ></v-select>
                                    </v-col>
                                     <v-col
                                    cols="12"
                                    md="6"
                                    >
                                      <v-select
                                            :items="['Yes','No','Undecided']"
                                            item-value="item_value"
                                            label="Built in Cabinets to Sides"
                                            v-model="section.built_in_cabinets_1"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                    <span>Mantel: Provided by Builder</span>
                                    </v-col>



                                </v-col>

                                <v-col
                                cols="12"
                                md="6"
                                >

                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                    <h3>FIREPLACE #2</h3>
                                    </v-col>

                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                     <v-text-field
                                        label="Stone Selection"
                                        v-model="section.stone_selection_2"
                                    />
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                      <v-select
                                            :items="['STD. 2 1/2','Custom','Undecided']"
                                            item-value="item_value"
                                            label="Hearth Selection:"
                                            v-model="section.hearth_selection_2"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                      <v-select
                                            :items="['Yes','No','Undecided']"
                                            item-value="item_value"
                                            label="Raised Hearth"
                                            v-model="section.raised_hearth_2"
                                        ></v-select>
                                    </v-col>
                                     <v-col
                                    cols="12"
                                    md="6"
                                    >
                                      <v-select
                                            :items="['Yes','No','Undecided']"
                                            item-value="item_value"
                                            label="Stone Height to Mantel"
                                            v-model="section.stone_height_to_mantel_2"
                                        ></v-select>
                                    </v-col>
                                     <v-col
                                    cols="12"
                                    md="6"
                                    >
                                      <v-select
                                            :items="['Yes','No','Undecided']"
                                            item-value="item_value"
                                            label="Stone Height to Ceiling"
                                            v-model="section.stone_height_to_ceiling_2"
                                        ></v-select>
                                    </v-col>
                                     <v-col
                                    cols="12"
                                    md="6"
                                    >
                                      <v-select
                                            :items="['Yes','No','Undecided']"
                                            item-value="item_value"
                                            label="STD. 6in Corner Returns"
                                            v-model="section.std_six_corner_returns_2"
                                        ></v-select>
                                    </v-col>
                                     <v-col
                                    cols="12"
                                    md="6"
                                    >
                                      <v-select
                                            :items="['Yes','No','Undecided']"
                                            item-value="item_value"
                                            label="Built in Cabinets to Sides"
                                            v-model="section.built_in_cabinets_2"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    md="6"
                                    >
                                    <span>Mantel: Provided by Builder</span>
                                    </v-col>



                                </v-col>

                            </v-row>

                            <v-divider></v-divider>

                            <v-row>

                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <h3>EXTERIOR STONE/BRICK</h3>
                                </v-col>
                                <v-col
                                cols="12"
                                >
                                     <v-text-field
                                        label="Stone Selection"
                                        v-model="section.stone_selection_3"
                                    />
                                </v-col>
                                <v-col
                                cols="12"
                                md="6"
                                >
                                     <v-text-field
                                        label="Sill Selection"
                                        v-model="section.sill_selection"
                                    />
                                </v-col>

                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <v-select
                                            :items="['Yes','No','Undecided']"
                                            item-value="item_value"
                                        label="Address Stone"
                                            v-model="section.address_stone"
                                    ></v-select>
                                </v-col>
                                 <v-col
                                cols="12"
                                md="6"
                                >
                                    <span>(Address stone location will be at the discretion of the Builder unless approved earlier in writing)</span>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                    label="Notes"
                                    v-model="section.notes"
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>
                        </v-container>
                </v-form>
              </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                           <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                    label="Assisted By"
                                    v-model="section.assisted_by"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                    label="Customer Signature"
                                    v-model="section.customer_signature"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="12"
                                >
                                <h4>
                                    Any changes to this document must be made in writing and signed by the customer, Thank you!
                                </h4>
                                </v-col>
                            </v-row>
                      <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-col  cols="12" class="text-right">
                          <div v-if="permissions.scope === 'create:users,fullaccess:staff'">
                        <v-btn
                          color="primary"
                          dark
                          class="text-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Submit
                        </v-btn>
                        </div>
                        <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Save</v-btn>
                    </v-col>
                        </v-col>
                      </template>
                      <v-card>
                        <v-card-title>Add Vendor Email</v-card-title>
                        <v-card-actions>
                        <v-card-text>
                          <v-container>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>
                                <v-col
                                     cols="12"
                                     md="4">

                                  <v-select
                                      v-model ="add_vendor_email"
                                     label="Add Vendor Email"
                                     :items="['Yes','No']"
                                      @change="add_vendor_email"
                                ></v-select>
                                 </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        </v-card-actions>
                      </v-card>
                             <v-expand-transition>
                          <v-card
                          v-if="add_vendor_email === 'Yes'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                            <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>

                               <!-- Partner 1 -->

                               <v-col
                                cols="12"
                                md="13"
                                class="card-title font-weight-light pt-6">
                                Vendor Email
                               </v-col>
                                <v-autocomplete
                                ref="user"
                                :items="vendor_email"
                                v-model="selected_vendor.Email"
                                item-text="Email"
                                label="Vendor Email"
                                prepend-icon="mdi-account-search"
                                outlined
                                solo
                                menu-props="auto"
                                class="px-3"
                                required
                                return-object
                                ></v-autocomplete>
                              </v-row>
                              </v-form>
                            </v-container>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sectionComplete"
                            >
                              Submit
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                               <v-card
                          v-if="add_vendor_email === 'No'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                                 <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sectionComplete"
                            >
                              Save
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                             </v-expand-transition>
                           </v-dialog>
                             </v-container>
                          </v-form>
                </base-material-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    name:'sectionD',
    data(){
        return{
          home_id: localStorage.getItem("homeid"),
          alert:false,
          dialog: null,
          valid: null,
          staff_email: 'steve@skelleymarketing.com',
          section_id: "D",
          add_vendor_email:'',
          vendor_email: [],
          selected_vendor: {
              ItemId:0,
              Vendor: '',
              Name:'',
              Email: '',
              ModifiedDate:''
          },
          default_vendor: {
              ItemId:0,
              Vendor: '',
              Name:'',
              Email: '',
              ModifiedDate:''
          },
          section: {
            date_field:"",
            closing_date:"",
            home_number:"",
            cell_number_one:"",
            cell_number_two:"",
            email_number_one:"",
            email_number_two:"",
            HomeOwner1FirstName:"",
            HomeOwner1LastName:"",
            HomeOwner2FirstName:"",
            HomeOwner2LastName:"",
            new_address: "",
            based_on_model_at:"",
            stone_selection_1:"",
            hearth_selection_1:"",
            raised_hearth_1:"",
            stone_height_to_mantel_1:"",
            stone_height_to_ceiling_1:"",
            std_six_corner_returns_1:"",
            built_in_cabinets_1:"",
            stone_selection_2:"",
            hearth_selection_2:"",
            raised_hearth_2:"",
            stone_height_to_mantel_2:"",
            stone_height_to_ceiling_2:"",
            std_six_corner_returns_2:"",
            built_in_cabinets_2:"",
            stone_selection_3:"",
            sill_selection:"",
            address_stone:"",
            notes:"",
            assisted_by:"",
            customer_signature:""
      },
      selected_user: {
            PrimaryEmailAddress:"",
            SecondaryEmailAddress:"",
            PrimaryFirstName: "",
            PrimaryLastName: "",
            SecondaryFirstName: "",
            SecondaryLastName: "",
            Address:"",
            City:"",
            State: "",
            Zip: "",
            BuildStart: "",
            BuildEnd:"",
            CellNumber:"",
            HomeNumber: "",
            WorkPhone:"",
            Role:"",
            Agent:""
		},
    }
    },
  created () {
    this.getVendorEmail()
    this.getUserInfo()
    this.getSection()
    },
    methods:{

      getSection() {
          axios
          .get(process.env.VUE_APP_GET_SECTION_URL,
          {
          params: {
              home_id: this.home_id,
              section_id: this.section_id
          }
          })
          .then(response => {
            const { data } = response
              if ( data ) {
                  this.section = JSON.parse(data['JsonDetails'])
              }
          })
          .catch(error => {
          console.log(error)
          })
      },
      getUserInfo(){
            axios
            .get(process.env.VUE_APP_GET_USER_URL_INFO,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {
              const { data } = response
              if ( data ) {
                    this.selected_user = data[0]
                }
            })
            .catch(error => {
            console.log(error)
            })
      },
      getVendorEmail(){
          axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,{})
          .then(response => {
            this.vendor_email = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      updateSection(){
          var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
          }
          axios
          .post(process.env.VUE_APP_UPDATE_SECTION_URL,body)
          .then(() => {
              this.alert = true
          })
          .catch(error => {
          console.log(error)
          })
      },
      sectionComplete(){
        var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
        }
        const theNewContent = {
        homeOwner: {
            ...this.selected_user
        },
        sectionData: [
            {Title:"Date",Value:this.section.date_field},
            {Title:"Based on Model At",Value:this.section.based_on_model_at},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #1 - Stone Selection",Value:this.section.stone_selection_1},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #1 - Hearth Selection",Value:this.section.hearth_selection_1},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #1 - Raised Hearth",Value:this.section.raised_hearth_1},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #1 - Raised Hearth",Value:this.section.stone_height_to_mantel_1},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #1 - Stone Height to Ceiling",Value:this.section.stone_height_to_ceiling_1},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #1 - STD. 6in Corner Returns",Value:this.section.std_six_corner_returns_1},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #1 - Built in Cabinets to Sides",Value:this.section.built_in_cabinets_1},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #2 - Stone Selection",Value:this.section.stone_selection_2},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #2 - Hearth Selection",Value:this.section.hearth_selection_2},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #2 - Raised Hearth",Value:this.section.raised_hearth_2},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #2 - Stone Height to Mantel",Value:this.section.stone_height_to_mantel_2},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #2 - Stone Height to Ceiling",Value:this.section.stone_height_to_ceiling_2},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #2 - STD. 6in Corner Returns",Value:this.section.std_six_corner_returns_2},
            {Title:"INTERIOR STONE/BRICK FIREPLACE #2 - Built in Cabinets to Sides",Value:this.section.built_in_cabinets_2},
            {Title:"EXTERIOR STONE/BRICK -Sill Selection",Value:this.section.stone_selection_3},
            {Title:"EXTERIOR STONE/BRICK - Stone Selection",Value:this.section.sill_selection},
            {Title:"EXTERIOR STONE/BRICK - Address Stone",Value:this.section.address_stone},
            {Title:"Notes",Value:this.section.notes},
            {Title:"Assisted By",Value:this.section.assisted_by},
            {Title:"Customer Signature",Value:this.section.customer_signature},
    ],
        vendorData: {
                ...this.selected_vendor
            }
        }
        axios
        .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
        .then(() => {
            this.alert = true
            
            if (this.add_vendor_email[0] === 'Y')
            this.sendEmail([this.selected_vendor.Email.Email,this.staff_email],this.staff_email,"vendor_section_submit_notification",theNewContent, this.section_id)
            })
        .catch(error => {
            console.log(error)
        })
        this.add_vendor_email = Object.assign({}, this.add_vendor_email)
        this.editedIndex = -1
        this.close()
        },
      close(){
          this.dialog = false
          this.$nextTick(() => {
            this.add_vendor_email = Object.assign({}, this.add_vendor_email)
            this.editedIndex = -1
          })
      },
      sendEmail(to,from,template,content,section){

        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "section": section
          }
        }

        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })

      }
    },
    computed: {
      homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
      },
      permissions(){
        return {scope: localStorage.getItem("permissions")}
      }
    }
}
</script>